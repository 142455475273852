body .p-tabview .p-tabview-panels{
    padding: 0.5em 0em;
}

body .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
body .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    border-color: $blue-color;
    color: $blue-color;
}

body .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    color: $blue-color;    
    box-shadow: none;
    border: 0px;
    //padding: 20px 0 16px 0;
    opacity: 0.8;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

body .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    //color: $blue-color;
    box-shadow: none;
    border: 0px;
    //padding: 16px 0 16px 0;
    opacity: 0.8;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

body .p-tabview .p-tabview-nav {
    background: #ffffff;
    //border-color: $blue-color;
    border: 1px solid $blue-color;
    border-width: 1px 0 0 0;
    //opacity: 0.2;
}
