// Переопределяет стили для групп полей библиотеки PrimeNG

.p-fieldset {
  padding-bottom: inherit;
  margin-top: 0.5rem;

  .p-fieldset-legend {
    border: 0 none;
    background-color: #FFFFFF;
    padding: 0px 10px;
  }

  .p-fieldset-content {
    padding: 0;
  }

  .p-fieldset-legend-text {
    font-weight: bold!important;
    @extend label;
  }
}
