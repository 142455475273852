@import "../css/variables.base.scss";

body .p-button {
  font-size: $base-font-size;
}

.app-btn-panel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 3px 0px;
  justify-items: center;
  align-items: center;
  button {
    margin: 2px 2px;
  }
}

.btn-separator {
  content: "";
  display: inline-block;
  background: #d5d5da;
  margin: 8px 8px;
  width: 2px;
  height: 24px;
}

.element-separator {
  content: "";
  display: inline-block;
  background: transparent;
  margin: 8px 8px;
  width: 2px;
  height: 24px;
}

.btn-panel-lbl {
  padding-left: 10px;
  white-space: nowrap;
  font-weight: 500;
  font-size: $base-font-size;
  line-height: 32px;
  color: $text-color;
}

.panel-lbl {
  padding-left: 10px;
  white-space: nowrap;
  font-size: $base-font-size;
  line-height: 32px;
  color: #6c6e6f;
}

body .p-button.app-btn-outlined {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: transparent;
  color: #56566A;
  border: 1px solid transparent;
  box-sizing: border-box;
}

body .p-button.app-btn-calcel-report {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  color: #56566A;
  border: 1px solid #CDCDD3;
  border-radius: 16px;
  box-sizing: border-box;
}

body .p-button.app-btn-apply-report {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: #FE833E;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  color: #FFFFFF;
  border: 1px solid #E66A25;
  border-radius: 16px;
  box-sizing: border-box;
}

body .p-button.app-btn-gray,
body .p-splitbutton.app-btn-gray > .p-button {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: #ffffff;
  color: #ff8630;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  box-sizing: border-box;
  margin-right: 5px;
}

body .p-button.app-btn-export {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background-color: #29ab72;
  color: #ffffff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  box-sizing: border-box;
  margin-right: 5px;
  
}

body .p-button.app-btn-gray,
body .p-button.app-btn-export,
body .p-button.app-btn-outlined {
  border-radius: 20px;
  padding: 10px 15px;
}

body .p-button.app-btn-gray:enabled:focus,
body .p-buttonset.app-btn-gray > .p-button:enabled:focus,
body .p-splitbutton.app-btn-gray > .p-button:enabled:focus,
body .p-button.app-btn-export:enabled:focus,
body .p-buttonset.app-btn-export > .p-button:enabled:focus,
body .p-splitbutton.app-btn-export > .p-button:enabled:focus,
body .p-button.app-btn-outlined:focus {
  //box-shadow: none;
}

body .p-button.app-btn-gray:enabled:hover,
body .p-buttonset.app-btn-gray > .p-button:enabled:hover,
body .p-splitbutton.app-btn-gray > .p-button:enabled:hover {
  background-color: #ff8630;
  border: none;
  color: #ffffff;
}

body .p-button.app-btn-export:enabled:hover,
body .p-buttonset.app-btn-export > .p-button:enabled:hover,
body .p-splitbutton.app-btn-export > .p-button:enabled:hover {
  background-color: #ffffff;
  border: none;
  color: #29ab72;
}

body .p-button.app-btn-gray:enabled:hover .fas,
body .p-button.app-btn-gray:enabled:hover .far,
body .p-button.app-btn-gray:enabled:hover .pi {
  color: #ffffff;
}

body .p-button.app-btn-export:enabled:hover .fas,
body .p-button.app-btn-export:enabled:hover .far,
body .p-button.app-btn-export:enabled:hover .pi {
  color: #29ab72;
}

body .p-button.app-btn-outlined:enabled:hover{
  background-color: #ffffff;
  color: #6a6356;
  border: 1px solid #ffffff;
}

body .p-button.app-btn-export .fas{
  color:#ffffff;
}

body .p-button.app-btn-export .far{
  color:#ffffff;
}

body .p-button.app-btn-export .pi{
 color:#ffffff;
}

.fas{
  color:#ff8630;
}

.far{
  color:#ff8630;
}

.pi{
 color:$grey-color;
}

.close-button {
  color: $grey-color;
}

.p-inputnumber-button,
.p-datepicker-trigger.p-button {
  background-color: transparent;
  border-color: #ced4da;
  border-left: none;
}
.p-inputnumber-button.p-inputnumber-button-down {
  border-top: none;
}


.far.fa-check-circle{
  color:#56cdaa;
}

.far.fa-arrow-alt-circle-right{
  color:#8b61fd;
}
