@import "../css/variables.base.scss";

body .p-dropdown {
    height: $input-height;
    font-size: $base-font-size;
}

body .p-dropdown .p-dropdown-label.p-placeholder,
body .p-dropdown .p-dropdown-label.p-inputtext,
body .p-dropdown-panel .p-dropdown-items,
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    font-size: $base-font-size;
    font-family: $font-family;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus,
body .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
}

body .p-multiselect:not(.p-disabled):hover {
    border-color: $base-color;
}

