@import "variables.base.scss";

body .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
body .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $base-color;
    background: $base-color;
}

body .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
body .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
body .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight.p-focus {
    box-shadow: 0 0 0 0.05rem $base-color;
    border-color: $base-color;
}
