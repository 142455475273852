@import "../css/variables.base.scss";

body .p-menuitem {
    font-size: $base-font-size + 1;
    font-family: $font-family;
    white-space: nowrap;
}

body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
body .p-menubar .p-menuitem > .p-menuitem-link .p-menuitem-text {
    font-size: $base-font-size + 1;
    font-family: $font-family;
    line-height: 16px;
    color: $text-color;
}

body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    font-size: $base-font-size + 7;
}

body .p-menubar {
    height: 52px;

    .p-submenu-list {
      width: auto;
    }
}

body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: none;
}
