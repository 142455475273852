.p-fileupload {
  font-size: small;

  .p-fileupload-buttonbar {
    padding: 0.5rem;

    .p-button {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      background-color: transparent;
      color: #6c6e6f;
      border: 1px solid transparent;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 10px 15px;
    }

    .p-button:focus {
      box-shadow: none;
    }

    .p-button:hover {
      background-color: #fcdecb;
      border: 1px solid #fcdecb;
      color: #4b2a12;
    }
  }

  .p-fileupload-content {
    padding: 0px;
  }

  .p-fileupload-row {
    div:first-child {
      display: none;
    }

    div:nth-child(2) {
      width: 60%;
    }

    div:last-child{
      width: 10%;
    }
  }
}


