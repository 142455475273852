body .p-treetable .p-treetable-thead > tr > th,
body .p-treetable .p-sortable-column.p-highlight {
    padding: 0.6rem;
    font-family: $font-family;
    font-weight: bold;
    font-size: $base-font-size;
    background-color: $header-background;
    color: $header-color;
    border: 1px solid #676779;
}

body .p-treetable .p-treetable-tbody > tr {
    outline-color: #e6e6e9;
}

body .p-treetable .p-treetable-tbody > tr.p-highlight {
    background-color: #e6e6e9;
}

body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon,
body .p-treetable .p-treetable-tfoot > tr > td,
body .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: $header-color;
}

body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon,
body .p-treetable .p-sortable-column .p-sortable-column-icon,
body .p-treetable .pi-filter-icon {
  color: $header-color;
  font-size: 11px;
}

body .p-treetable .p-treetable-tbody > tr > td {
    font-family: $font-family;
    font-size: $base-font-size;
    font-style: normal;
    line-height: 16px;
    color: $text-color;
    word-break: break-word;
    padding: 0.6rem;
    border: 1px solid #e6e6e9;
    border-right: 1px solid #e6e6e9;

    &:first-child {
        border-bottom: 1px solid #e6e6e9;
        border-top: 1px solid #e6e6e9;
        border-left: none;
        border-right: none;
    }

    &:last-child {
        border-bottom: 1px solid #e6e6e9;
        border-top: 1px solid #e6e6e9;
        border-left: none;
        border-right: none;
    }
}

body .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    height: 1.3em;
}
