@keyframes startSpinAnim {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.startspinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1003;
    overflow: hidden;
}
.startspinner div:first-child {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    box-shadow: 0 3px 3px 0 red;
    transform: translate3d(0, 0, 0);
    animation: startSpinAnim 2s linear infinite;
    -webkit-animation: startSpinAnim 2s linear infinite;
}
.startspinner div:first-child:after,
.startspinner div:first-child:before {
    content: "";
    position: absolute;
    border-radius: 50%;
}
.startspinner div:first-child:before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    box-shadow: 0 3px 3px 0 yellow;
    animation: startSpinAnim 3s linear infinite;
    -webkit-animation: startSpinAnim 3s linear infinite;
}
.startspinner div:first-child:after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    box-shadow: 0 3px 3px 0 darkblue;
    animation: startSpinAnim 1.5s linear infinite;
    -webkit-animation: startSpinAnim 1.5s linear infinite;
}
