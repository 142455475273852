// содержит общие атрибуты для всех стилей

$font-family: 'Roboto';
$input-height: 32px;
$base-font-size: 13px;
$lbl-color: #787888;
$base-color: #E9863A;
$base-color-lite: #FFF8F0;
$text-color: #0D0D0D;
$header-color: #0D0D0D;
$orange-color: #ff8630;
$grey-color: #787888;
$blue-color: #0066A6;
