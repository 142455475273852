@import "../css/variables.base.scss";

.search-input {
    width: 300px;
}

.blue-board {
    border: 1px solid #0066A6;
    border-width: 1px 0 0 0;
}

body #actDate, #stampDate, #docDateDelivery, #contractDate, #docDateDone, #limitationTime, #limitationTime, #dvcValidDate, #dvcManufactureYear, #workPeriodTo, #workPeriodFrom, #newDeviceValidDate, #newDeviceNextValidDate {
    background-image: url(../assets/icons/small_icons/datapicker_icon.svg);
    background-repeat: no-repeat;
    background-position: 93% 50%;
}

.field-checkbox p-checkbox{
    flex: 1 1 auto;
    margin: 0 24px;
  }

input,
body .p-inputtext {
    height: $input-height;
    font-size: $base-font-size;
    font-family: $font-family;
    color: $text-color;
}

body .p-inputtext:enabled:hover,
body .p-multiselect:not(.p-disabled):hover,
body .p-dropdown:not(.p-disabled):hover {
    border-color: $base-color;
}

body .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.05rem $base-color;
    border-color: $base-color;
}

body .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: $base-color-lite;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: $base-color-lite;
}
