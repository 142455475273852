@import "../css/variables.base.scss";
// описывает базовые стили для редакторов

.field-checkbox {
    // display: flex;
    margin: 0 -5px;
    padding: 17px 0 23px 0;
  }

.app-control-rows {
    //-- Стили с рамещением метки и контролла построчно
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 5px;
    label {
        width: 100%;
        font-family: $font-family;
        font-size: $base-font-size;
        padding-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .app-control {
        width: 100%;
        input {
            font-family: $font-family;
            font-size: $base-font-size;
            width: 100%;
        }
    }
}

.app-control-cols {
    //-- Стили с рамещением метки и контролла колонками
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 2px;
    label {
        width: 20%;
        font-family: $font-family;
        padding-bottom: 5px;
        font-size: $base-font-size;
        font-weight: 500;
    }

    .app-control {
        width: 80%;
        input {
            width: 100%;
            font-family: $font-family;
            font-size: $base-font-size;
        }
        .p-multiselect,
        .p-dropdown,
        .p-inputtextarea,
        .p-password {
            width: 100%;
            height: auto;
            font-family: $font-family;
            font-size: $base-font-size;
        }
    }
}

.app-required:before {
    content: "*";
    color: red;
}

.app-element-center {
    display: flex;
    justify-content: center;
}

body .p-dialog .p-dialog-header .p-dialog-title {
    font-family: $font-family;
    font-weight: 700;
    font-size: 15px;
    color: $header-color;
}

body .p-dialog .p-dialog-header {
    border-bottom: 1px solid #dee2e6;
    border-radius: 10px 10px 0px 0px;
    width: 792px;
    height: 60px;
}

body .p-dialog {
    border-radius: 10px 10px 10px 10px;
}

body .p-dialog .p-dialog-content {
    width: 792px;
    left: 0px;
    top: 0px;
    border-radius: 0px 0px 0px 0px;
}

body .p-dialog .p-dialog-footer {
    border-radius: 0px 0px 10px 10px;
}

body .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    font-family: $font-family;
    font-size: $base-font-size;
}
