@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Thin.woff2") format("woff2"), url("../assets/fonts/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("../assets/fonts/Roboto-Thin.woff2") format("woff2"), url("../assets/fonts/Roboto-Thin.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Light.woff2") format("woff2"), url("../assets/fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-RegularItalic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto-RegularItalic";
  src: url("../assets/fonts/Roboto-RegularItalic.woff2") format("woff2"),
    url("../assets/fonts/Roboto-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("../assets/fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Bold.woff2") format("woff2"), url("../assets/fonts/Roboto-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/Roboto-Bold.woff2") format("woff2"), url("../assets/fonts/Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Black.woff2") format("woff2"), url("../assets/fonts/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../assets/fonts/Roboto-Black.woff2") format("woff2"), url("../assets/fonts/Roboto-Black.woff") format("woff");
}
