$header-color: #888b8d;
$header-background: #f1f1f3;

body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-sortable-column.p-highlight {
  padding: 0.6rem;
  font-family: $font-family;
  font-weight: bold;
  font-size: $base-font-size;
  background-color: $header-background;
  color: $header-color;
  border: 1px solid #e2e2e4;
}

body .p-datatable .p-datatable-tbody > tr {
  outline-color: #e6e6e9;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #e6e6e9;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
body .p-datatable .p-datatable-tfoot > tr > td,
body .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: $header-color;
}

body .p-datatable .p-sortable-column.p-highlight:hover,
body .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon,
body .p-datatable .p-sortable-column.p-highlight:hover .pi-filter-icon,
body .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon,
body .p-datatable .p-sortable-column:not(.p-highlight):hover .pi-filter-icon {
  color: $header-color;
}

body .p-column-filter-menu-button.p-column-filter-menu-button-open,
body .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: none;
}

body .p-column-filter-menu-button:focus {
  box-shadow: 0 0 0 0.1rem $base-color;
}

body .p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background-color: $base-color;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
body .p-datatable .p-sortable-column .p-sortable-column-icon,
body .p-datatable .pi-filter-icon {
  color: $header-color;
  font-size: 11px;
}

body .p-column-filter-menu-button {
  height: 15px;
}

body .p-datatable .p-datatable-tbody > tr > td {
  font-family: $font-family;
  font-size: $base-font-size;
  font-style: normal;
  line-height: 16px;
  color: $text-color;
  word-break: break-word;
  padding: 0.6rem;
  border: 1px solid #e6e6e9;
  border-right: 1px solid #e6e6e9;

  &:first-child {
    border-bottom: 1px solid #e6e6e9;
    border-top: 1px solid #e6e6e9;
    border-left: none;
    border-right: none;
  }

  &:last-child {
    border-bottom: 1px solid #e6e6e9;
    border-top: 1px solid #e6e6e9;
    border-left: none;
    border-right: none;
  }
}

body .p-column-filter-overlay {
  width: 250px;
}

body .p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

body .p-datatable .p-datatable-footer {
  font-size: $base-font-size;
  background-color: $header-background;
  color: $header-color;
}

/** Стандартный подвал не работает нормально */
.app-table-footer {
  padding: 8px 5px;
  height: 30px;
  background-color: $header-background;
  color: $header-color;
  font-weight: bold;
  font-size: $base-font-size;
}

.col-width-1-5 {
  width: 1.5%;
}

.col-width-2 {
  width: 2%;
}

.col-width-3 {
  width: 3%;
}

.col-width-4 {
  width: 4%;
}

.col-width-5 {
  width: 5%;
}

.col-width-6 {
  width: 6%;
}

.col-width-7 {
  width: 7%;
}

.col-width-8 {
  width: 8%;
}

.col-width-9 {
  width: 9%;
}

.col-width-10 {
  width: 10%;
}

.col-width-12 {
  width: 12%;
}

.col-width-15 {
  width: 15%;
}

.col-width-20 {
  width: 20%;
}

.col-width-30 {
  width: 30%;
}

.col-width-50 {
  width: 50%;
}

.col-width-70 {
  width: 70%;
}

.col-width-92 {
  width: 92%;
}

.col-width-auto {
  width: auto;
}

.col-width-50px {
  width: 50px;
}

.col-width-130px {
  width: 130px;
}

.col-width-100px {
  width: 130px;
}

.col-width-90px {
  width: 90px;
}
