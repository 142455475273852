@import "css/variables.base.scss"; // должен быть первым
@import "css/fonts.scss";
@import "css/index-spinner.scss";
@import "css/prime-accordion.scss";
@import "css/prime.buttons.scss";
@import "css/prime.calendar.scss";
@import "css/prime.checkbox.scss";
@import "css/prime.dialog.scss";
@import "css/prime.dropdown.scss";
@import "css/prime.input.scss";
@import "css/prime.table.scss";
@import "css/prime.menuitem.scss";
@import "css/prime.badge.scss";
@import "css/prime.tree-table.scss";
@import "css/prime.tab-panel.scss";
@import "css/prime-toolbar.scss";
@import "css/prime.file-upload.scss";
@import "css/prime-fieldset.scss";

body {
  font-family: $font-family;
  margin: 0px;
  min-height: 100%;
  height: 100%;
  background-image: url("./assets/icons/brand.svg"), url("./assets/icons/noise_pattern.png");
  background-repeat: no-repeat, repeat;
  font-weight: normal;
  font-size: $base-font-size;

  .action-panel {
    padding-bottom: 7px;
  }
}

body .p-component {
  font-family: $font-family;
}

body .p-disabled,
body .p-component:disabled {
  background: #e6e6e9;
  color: $text-color;
  opacity: 1;
}

label {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: $base-font-size;
  line-height: normal;
  color: $lbl-color;
}

.app-center {
  display: flex;
  justify-content: center;
}

.rightTextAlignment {
  text-align: end;
}

.content {
  height: 100%;
}

.table-wrapper {
  height: calc(100% - 180px);
}

.field-checkbox p-checkbox {
  margin: 0 10px;
  padding: 10 0 10;
}
