@import "variables.base.scss";

body .p-badge.p-badge-danger {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 1s infinite;
    font-size: 10px;
}

@keyframes pulse-red {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        box-shadow: 0 0 0 15px rgba(255, 82, 82, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}
